'use client'

import { type FallbackNs } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { type Namespace, type TFunction } from 'i18next'

import { useClientTranslation } from '@/app/i18n/client'
import { client } from '@/lib/api'
import { YYYY_MM_DD } from '@/lib/constants/dateFormat'
import { isEmpty } from '@/lib/utils'
import { type AccountResponse, type TableData, type UseQueryOptions } from '@/types'
import { type paths } from '@/types/schema'

export const GET_ACCOUNTS = '/accounts'

// アカウント詳細情報取得
export function useFetchTableData({
  params,
  reactQuery,
}: UseQueryOptions<paths[typeof GET_ACCOUNTS]['get']>) {
  const { t } = useClientTranslation(['common'])
  const facilityId = params?.query?.facilityId ?? ''

  return useQuery({
    ...reactQuery,
    queryKey: [GET_ACCOUNTS, facilityId],
    queryFn: async ({ signal }) => {
      const { data } = await client.GET(GET_ACCOUNTS, { params: { query: { facilityId } }, signal })
      return data
    },
    // テーブルデータに整形して返却
    select(accounts) {
      const { list = [] } = accounts || {}
      return convertToTableData(list, facilityId, t)
    },
    enabled: !!facilityId,
  })
}

// テーブルデータ変換
export function convertToTableData(
  accounts: AccountResponse[],
  facilityId: string,
  t: TFunction<FallbackNs<Namespace>, undefined>
): TableData[] {
  return accounts.map((data) => {
    const {
      fikaigoId,
      displayName = '',
      mfaConfig: { providers = [], enrolledAt = '' },
    } = data

    return {
      fikaigoId,
      displayName: isEmpty(displayName) ? t('labels.notSet') : displayName,
      facilityId,
      enrolledAt:
        isEmpty(providers) || isEmpty(enrolledAt)
          ? '-'
          : `${providers?.join(', ').toUpperCase()} ${t('labels.registrationDate')}：${dayjs(enrolledAt).format(YYYY_MM_DD)}`,
    } as TableData
  })
}
