import createClient from 'openapi-fetch'

import { env } from '@/env'
import { type paths } from '@/types/schema'

export type OpenApiFetchClient = ReturnType<typeof createClient<paths>>

const baseUrl = env.NEXT_PUBLIC_API_URL || ''

declare global {
  // eslint-disable-next-line no-var
  var fetchClient: OpenApiFetchClient
}

const fetchClient = globalThis.fetchClient || createClient<paths>({ baseUrl, mode: 'cors' })
if (env.NEXT_PUBLIC_ENVIRONMENT !== 'prd') {
  globalThis.fetchClient = fetchClient
}

export const client = fetchClient
