import dayjs from 'dayjs'
import * as yup from 'yup'

const I18nValidationKey = {
  INVALID: 'invalid',
  REQUIRED: 'required',
  MAX_LENGTH: 'maxLength',
  IS_NUMBER: 'isNumber',
  EQUAL_TO: 'equalTo',
  IS_AFTER_THAN: 'isAfterThan',
  IS_SAME_OR_AFTER_THAN: 'isSameOrAfterThan',
  IS_BEFORE_THAN: 'isBeforeThan',
  MIN: 'min',
} as const

yup.addMethod(yup.string, 'equalTo', function (ref: { key: string; label: string }) {
  return this.oneOf([yup.ref(ref.key), ''], (params) => ({
    key: I18nValidationKey.EQUAL_TO,
    params: { ...params, refLabel: ref.label },
  }))
})

yup.addMethod(yup.mixed, 'isBeforeThan', function (ref: { key: string; label: string }) {
  return this.test({
    name: 'isBeforeThan',
    message: (params) => ({
      key: I18nValidationKey.IS_BEFORE_THAN,
      params,
    }),
    skipAbsent: true,
    exclusive: true,
    params: { refLabel: ref.label },
    test(value) {
      const refValue = this.resolve(yup.ref(ref.key))
      if (!value || !refValue) {
        return true
      }
      if (!dayjs.isDayjs(value) || !dayjs.isDayjs(refValue)) {
        return false
      }
      return value.isBefore(refValue)
    },
  })
})

yup.addMethod(yup.mixed, 'isAfterThan', function (ref: { key: string; label: string }) {
  return this.test({
    name: 'isAfterThan',
    message: (params) => ({
      key: I18nValidationKey.IS_AFTER_THAN,
      params,
    }),
    skipAbsent: true,
    exclusive: true,
    params: { refLabel: ref.label },
    test(value) {
      const refValue = this.resolve(yup.ref(ref.key))
      if (!value || !refValue) {
        return true
      }
      if (!dayjs.isDayjs(value) || !dayjs.isDayjs(refValue)) {
        return false
      }
      return value.isAfter(refValue)
    },
  })
})

yup.addMethod(yup.mixed, 'isSameOrAfterThan', function (ref: { key: string; label: string }) {
  return this.test({
    name: 'isSameOrAfterThan',
    message: (params) => ({
      key: I18nValidationKey.IS_SAME_OR_AFTER_THAN,
      params,
    }),
    skipAbsent: true,
    exclusive: true,
    params: { refLabel: ref.label },
    test(value) {
      const refValue = this.resolve(yup.ref(ref.key))
      if (!value || !refValue) {
        return true
      }
      if (!dayjs.isDayjs(value) || !dayjs.isDayjs(refValue)) {
        return false
      }
      // @ts-expect-error 一時的にエラー解除
      return value.isSameOrAfter(refValue, 'd')
    },
  })
})

yup.addMethod(yup.array, 'required', function () {
  return this.test({
    name: 'required',
    skipAbsent: true,
    exclusive: true,
    test(value) {
      if (Array.isArray(value) && value.length > 0) return true
      return this.createError({
        message(params) {
          return {
            key: I18nValidationKey.REQUIRED,
            params,
          }
        },
      })
    },
  })
})

yup.addMethod(
  // @ts-ignore
  yup.Schema,
  'deepWhen',
  function (options: {
    is: (value: any, values: any, context: yup.TestContext) => boolean
    then: (schema: yup.Schema) => yup.Schema
    otherwise?: (schema: yup.Schema) => yup.Schema
  }) {
    const { is, then, otherwise } = options
    // @ts-ignore
    return this.test({
      name: 'deepWhen',
      exclusive: true,
      skipAbsent: false,
      test(value: any, ctx: yup.TestContext) {
        try {
          const ctxFrom = ctx.from ?? []
          const values = ctxFrom?.[ctxFrom.length - 1]?.value ?? {}
          if (is(value, values, ctx)) {
            then(this.schema).validateSync(value)
          } else if (otherwise) {
            otherwise(this.schema).validateSync(value)
          }
          return true
        } catch (error: any) {
          return this.createError({
            message: error.message,
          })
        }
      },
    })
  }
)

yup.addMethod

yup.setLocale({
  string: {
    email: (params) => ({ key: I18nValidationKey.INVALID, params }),
    matches: (params) => ({ key: I18nValidationKey.INVALID, params }),
    max: (params) => ({ key: I18nValidationKey.MAX_LENGTH, params }),
  },
  number: {
    integer: (params) => ({ key: I18nValidationKey.INVALID, params }),
    min: (params) => ({ key: I18nValidationKey.MIN, params }),
  },
  mixed: {
    default: (params) => ({ key: I18nValidationKey.INVALID, params }),
    required: (params) => ({ key: I18nValidationKey.REQUIRED, params }),
    notNull: (params) => ({ key: I18nValidationKey.REQUIRED, params }),
    notType(params) {
      switch (params.type) {
        case 'number': {
          if (isNaN(params.value) || Number.isNaN(params.value)) {
            return !params.spec.optional ? { key: I18nValidationKey.REQUIRED, params } : null
          }
          return { key: I18nValidationKey.IS_NUMBER, params }
        }
        default:
          return { key: I18nValidationKey.INVALID, params }
      }
    },
  },
})
