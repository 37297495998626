'use client'

import { useQuery } from '@tanstack/react-query'

import { client } from '@/lib/api'
import { type UseQueryOptions } from '@/types'
import { type paths } from '@/types/schema'

const GET_TENANT = '/tenants'

// テナント情報取得
export function useFetchTenant({ reactQuery }: UseQueryOptions<paths[typeof GET_TENANT]['get']>) {
  return useQuery({
    ...reactQuery,
    queryKey: [GET_TENANT],
    queryFn: async ({ signal }) => {
      const { data } = await client.GET(GET_TENANT, { signal })
      return data
    },
  })
}
