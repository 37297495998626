'use client'

import { useMutation } from '@tanstack/react-query'
import { type Auth, signOut } from 'firebase/auth'
import { t } from 'i18next'
import { useRouter } from 'next/navigation'

import { client } from '@/lib/api'
import { ROUTES } from '@/lib/constants/routes'
import { errorHandler } from '@/lib/utils/errors'

const REVOKE_REFRESH_TOKEN = '/accounts/{uid}/refreshtokens'

// リフレッシュトークン取り消し
export function useRevokeRefreshToken(auth: Auth) {
  const router = useRouter()

  const { mutate, isPending, isError } = useMutation({
    mutationFn: (uid: string) => {
      return client.DELETE(REVOKE_REFRESH_TOKEN, { params: { path: { uid } } })
    },
    onSuccess: async () => {
      await signOut(auth)
      router.replace(ROUTES.LOGIN)
    },
    onError: (e) => {
      errorHandler(e)
    },
  })

  return {
    signOut: () => {
      const { uid = '' } = auth.currentUser ?? {}
      // uidが取得できない場合は、エラーを表示する
      if (uid === '') {
        errorHandler(new Error(t('messages.signOutFailed')))
      }
      mutate(uid)
    },
    isPending,
    isError,
  }
}
