'use client'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { client } from '@/lib/api'

import { GET_ACCOUNT } from './useFetchAccount'

const RESET_PASSWORD = '/accounts/{fikaigoId}/password'

// パスワードリセット
export function useResetPassword() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (fikaigoId: string) => {
      return client.PUT(RESET_PASSWORD, { params: { path: { fikaigoId } } })
    },
    onSettled: (_data, _error, fikaigoId) => {
      queryClient.invalidateQueries(
        {
          queryKey: [GET_ACCOUNT, { path: { fikaigoId } }],
          // 非アクティブなクエリも再fetchさせる
          refetchType: 'all',
        },
        { cancelRefetch: !!fikaigoId }
      )
    },
  })
}
