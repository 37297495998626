'use client'
import { useContext, useEffect } from 'react'
import {
  type FallbackNs,
  initReactI18next,
  useTranslation,
  type UseTranslationResponse,
} from 'react-i18next'
import i18next, { type Namespace } from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'

import '../extensions/i18n-yup'

import { availableLanguages, getI18nextOptions, localeCookieName } from '../config'

import { ClientTranslationContext } from './context'

const runsOnServerSide = typeof window === 'undefined'

i18next
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      if (namespace === 'common' || namespace === 'validation') {
        return import(`../locales/${language}/${namespace}.json`)
      }
      return import(`../locales/${language}/features/${namespace}.json`)
    })
  )
  .init({
    ...getI18nextOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
      lookupCookie: localeCookieName,
    },
    preload: runsOnServerSide ? availableLanguages : [],
  })

export function useClientTranslation(ns?: Namespace, options?: Record<string, unknown>) {
  const { lang } = useContext(ClientTranslationContext)
  const ret: UseTranslationResponse<FallbackNs<Namespace>, undefined> = useTranslation(
    ns ? [...(Array.isArray(ns) ? ns : [ns]), 'common'] : undefined,
    options
  )
  const { i18n } = ret

  if (runsOnServerSide) {
    if (lang && i18n.resolvedLanguage !== lang) {
      i18n.changeLanguage(lang)
    }
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (lang && i18n.resolvedLanguage !== lang) {
        i18n.changeLanguage(lang)
      }
    }, [lang, i18n])
  }

  return { ...ret, locale: lang }
}
