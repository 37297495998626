import { notify } from '@/components'
import { isPrd } from '@/env'

export const errorHandler = (error: Error) => {
  if (isPrd) {
    console.error('Error:', error)
  }

  return notify.error(error?.message ?? 'エラーが発生しました。')
}
