export function sleep(callback: () => void = () => {}, seconds: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      callback()
      resolve()
    }, seconds * 1000)
  })
}

export function isEmpty<T>(value: T | null | undefined): boolean {
  if (value === null || value === undefined) return true

  if (typeof value === 'string') {
    return value === ''
  }

  if (Array.isArray(value)) {
    return value.length === 0
  }

  return false
}
