'use client'

import { useQuery } from '@tanstack/react-query'

import { client } from '@/lib/api'
import { type UseQueryOptions } from '@/types'
import { type paths } from '@/types/schema'

const GET_FACILITIES = '/facilities'

// 施設情報取得
export function useFetchFacilities({
  reactQuery,
}: UseQueryOptions<paths[typeof GET_FACILITIES]['get']>) {
  return useQuery({
    ...reactQuery,
    queryKey: [GET_FACILITIES],
    queryFn: async ({ signal }) => {
      const { data } = await client.GET(GET_FACILITIES, { signal })
      return data
    },
  })
}
