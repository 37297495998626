'use client'

import { useCallback, useState } from 'react'

import { useClientTranslation } from '@/app/i18n/client'

export const useCopyClipboard = () => {
  const { t } = useClientTranslation(['common'])

  const [hasCopied, setHasCopied] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const onCopy = useCallback(
    async (text: string) => {
      try {
        await navigator.clipboard.writeText(text)
        setHasCopied(true)
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message)
          setError(e)
          return
        }

        console.error(e)
        setError(new Error(t('messages.failedToCopy')))
      }
    },
    [t]
  )

  // 今回は一度表示したら、消す必要がないのでコメントアウト
  // useEffect(() => {
  //   let timeoutId: number | null = null
  //   if (hasCopied) {
  //     timeoutId = window.setTimeout(() => {
  //       setHasCopied(false)
  //     }, 1500)
  //   }

  //   return () => {
  //     if (timeoutId) {
  //       window.clearTimeout(timeoutId)
  //     }
  //   }
  // }, [hasCopied])

  return {
    onCopy,
    hasCopied,
    setHasCopied,
    isCopyError: !!error,
    error,
  }
}
