'use client'

import { useEffect } from 'react'

// ページ離脱時に確認メッセージを表示させる
export function usePageLeaveGuard(enabled: boolean = true) {
  useEffect(() => {
    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault()
      return (e.returnValue = '')
    }

    if (enabled) {
      window.addEventListener('beforeunload', beforeUnload)

      return () => {
        window.removeEventListener('beforeunload', beforeUnload)
      }
    }
  }, [enabled])
}
